import { gql } from "@apollo/client";

export const GET_COURSE_ENROLLED_USERS = gql`
  query enrolledUsers($course_id: bigint, $partner_ids: [bigint!]!) {
    courses_user_course_enrolment(
      where: {
        user: { partner_users: { partner_id: { _in: $partner_ids } } }
        course_id: { _eq: $course_id }
      }
    ) {
      enroll_status
      created_at
      user_id
      course_id
      user {
        id
        name
        email
        date_of_birth
        gender
        idp_user_id
        is_mobile_number_verified
        is_email_verified
        mobile_number
        partner_id
        partner {
          name
        }
      }
      id
      course_obj_relation {
        full_name
      }
    }
  }
`;

export const GET_COURSE_UNENROLLED_USERS = gql`
  query getUsers($where: courses_users_bool_exp = {}) {
    courses_users(where: $where, limit: 20) {
      activation_end_date
      activation_start_date
      active
      created_at
      email
      idp_user_id
      instructor_id
      id
      mobile_number
      name
      partner_id
    }
  }
`;

export const CHECK_COURSE_COMPLETION_CRITERIA_TOPIC = gql`
  query MyQuery($mapping_id: Int, $course_id: Int) {
    courses_course_completion_criteria(
      where: {
        mapping_id: { _eq: $mapping_id }
        course_id: { _eq: $course_id }
      }
    ) {
      id
    }
  }
`;

export const GET_USER_SCORM_PROGRESS = gql`
  query MyQuery($user_id: Int, $mapping_id: Int) {
    courses_course_scorm_track_data(
      where: { user_id: { _eq: $user_id }, mapping_id: { _eq: $mapping_id } }
    ) {
      score_raw
      attempt_id
      score_min
      score_max
      lesson_status
      success_status
      scorm_attempt {
        attempt_completed
        attempt_end
        attempt_number
        attempt_start
      }
    }
  }
`;

export const GET_USER_QUIZ_PROGRESS = gql`
  query MyQuery($user_id: Int, $mapping_id: Int) {
    courses_user_course_quiz_attempt(
      where: { user_id: { _eq: $user_id }, mapping_id: { _eq: $mapping_id } }
    ) {
      result
      attempt_completed
      attempt_end
      attempt_number
      attempt_percentage
      attempt_start
      max_marks
      total_score
    }
  }
`;

export const GET_USER_COURSE_CERTIFICATE = gql`
  query MyQuery($course_id: bigint, $user_id: bigint) {
    courses_certificates(
      where: { course_id: { _eq: $course_id }, user_id: { _eq: $user_id } }
    ) {
      id
      created_at
    }
  }
`;

export const GET_USERS_COURSE_PROGRESS = gql`
  query getUsersCourseProgress(
    $id: bigint
    $course_id: Int
    $userSearchExp: courses_users_bool_exp!
  ) {
    courses_course_scorm_track_data(
      where: { user: $userSearchExp, course_id: { _eq: $course_id } }
    ) {
      course_id
      mapping_id
      lesson_status
      score_raw
      user_id
      scorm_attempt {
        attempt_end
        attempt_number
        attempt_start
      }
      created_at
    }
    courses_user_course_quiz_attempt(
      where: { user: $userSearchExp, course_id: { _eq: $course_id } }
    ) {
      result
      total_score
      attempt_end
      attempt_number
      attempt_start
      course_id
      mapping_id
      user_id
      created_at
    }
    courses_certificates(
      where: { user: $userSearchExp, course_id: { _eq: $id } }
    ) {
      id
      created_at
      user_id
      course_id
    }
    courses_user_course_enrolment(
      where: { user: $userSearchExp, course_id: { _eq: $course_id } }
      order_by: { user: { name: asc } }
    ) {
      enroll_status
      created_at
      user_id
      course_id
      partner_id
      user {
        id
        name
        email
        date_of_birth
        gender
        idp_user_id
        is_mobile_number_verified
        is_email_verified
        mobile_number
        partner_id
        partner {
          name
        }
        partner_users {
          partner {
            name
            id
          }
          partner_data
        }
        address_complete
        address {
          adress_type
          city_town
          country
          district
          location
          pincode
          state
        }
        user_family_details {
          member_type
          name
          user_id
        }
        users_tags_relation {
          name
          value
          user_id
        }
      }
      id
      course_obj_relation {
        full_name
        nsqf_level
      }
      partner_data
    }
    courses_user_course_complete(
      where: { user: $userSearchExp, course_id: { _eq: $course_id } }
    ) {
      user_id
      course_id
      completed_on
      completed
      created_at
    }
  }
`;

export const GET_USERS_COURSE_PROGRESS_FILTERED = gql`
  query getUsersCourseProgress(
    $id: bigint
    $course_id: Int
    $userSearchExp: courses_users_bool_exp!
  ) {
    courses_course_scorm_track_data(
      where: { user: $userSearchExp, course_id: { _eq: $course_id } }
    ) {
      course_id
      mapping_id
      lesson_status
      score_raw
      user_id
      scorm_attempt {
        attempt_end
        attempt_number
        attempt_start
      }
      created_at
    }
    courses_user_course_quiz_attempt(
      where: { user: $userSearchExp, course_id: { _eq: $course_id } }
    ) {
      result
      total_score
      attempt_end
      attempt_number
      attempt_start
      course_id
      mapping_id
      user_id
      created_at
    }
    courses_certificates(
      where: { user: $userSearchExp, course_id: { _eq: $id } }
    ) {
      id
      created_at
      user_id
      course_id
    }
    courses_user_course_enrolment(
      where: { user: $userSearchExp, course_id: { _eq: $course_id } }
      order_by: { user: { name: asc } }
    ) {
      enroll_status
      created_at
      user_id
      user {
        id
        name
        email
        gender
        mobile_number
      }
      id
    }
    courses_user_course_complete(
      where: { user: $userSearchExp, course_id: { _eq: $course_id } }
    ) {
      user_id
      course_id
      completed_on
      completed
      created_at
    }
  }
`;

export const GET_COURSE_COMPLETION_DESC = gql`
  query MyQuery($id: bigint) {
    courses_course(where: { id: { _eq: $id } }) {
      course_completion_desc
    }
  }
`;
