import React, { useState } from "react";
import { asdmCourseReportConfig } from "../../../api/Consts";
import ASDMStatsFormBuilder from "formBuilders/ASDMStatsFormBuilder";
import { Form } from "skillstrainer-resource-library";
import Container from "components/Container/Container";
import { GET_USERS_COUNT_BY_DATE } from "GraphQl/Queries/User";
import { useLazyQuery } from "@apollo/client";
import _ from "lodash";

export default function ASDMStats() {
  const [instituteAndDistrictCount, setInstituteAndDistrictCount] = useState(
    {}
  );
  const [runQueryToGetUserCount, { data: usersCount, loading, error }] =
    useLazyQuery(GET_USERS_COUNT_BY_DATE, {
      fetchPolicy: "network-only",
    });

  const filterStats = async (user) => {
    try {
      const startDate = user?.start_date?.split("T");
      const endDate = user?.end_date?.split("T");

      const { data } = await runQueryToGetUserCount({
        variables: {
          startDate: startDate[0] || null,
          endDate: endDate[0] || null,
          course_ids: user?.course_id,
          project_id: asdmCourseReportConfig.asdmProjectId,
        },
      });

      const districtStudentCountMap = {};
      const instituteStudentCountMap = {};

      data?.courses_users.forEach((user) => {
        const userDistrict =
          _.get(user, "batch_enrollments.0.batch.institute.district") ||
          "NO DISTRICT";

        if (!districtStudentCountMap[userDistrict])
          districtStudentCountMap[userDistrict] = 1;
        else districtStudentCountMap[userDistrict]++;

        const userInstitute =
          _.get(user, "batch_enrollments.0.batch.institute.name") ||
          "NO INSTITUTE";

        if (!instituteStudentCountMap[userInstitute])
          instituteStudentCountMap[userInstitute] = 1;
        else instituteStudentCountMap[userInstitute]++;
      });
      setInstituteAndDistrictCount({
        districtStudentCountMap,
        instituteStudentCountMap,
      });
      console.log(
        JSON.stringify(
          {
            districtStudentCountMap,
            instituteStudentCountMap,
          },
          null,
          2
        )
      );
    } catch (error) {
      console.error("Error while fetching data:", error.message);
    }
  };

  return (
    <>
      <div>
        <Container>
          <Form
            formBuilder={ASDMStatsFormBuilder}
            initValues={{
              course_id: asdmCourseReportConfig?.coursesIds,
            }}
            className="mt-2 grid grid-cols-4 gap-x-6 mx-2"
            submitButton={{
              text: "Filter",
              className:
                "btn-primary bg-orange text-sm font-semibold text-white rounded-md px-6",
            }}
            onSubmit={(filter) => filterStats(filter)}
          />

          <div className="mx-auto mt-8">
            <div className="bg-gray-200 p-6 rounded-md shadow-md">
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <h3 className="text-lg font-bold mb-2">
                    District Student Count
                  </h3>
                  <table className="table-auto">
                    <thead>
                      <tr>
                        <th className="border px-4 py-2">District</th>
                        <th className="border px-4 py-2">Count</th>
                      </tr>
                    </thead>
                    <tbody>
                      {instituteAndDistrictCount.districtStudentCountMap &&
                        Object.entries(
                          instituteAndDistrictCount.districtStudentCountMap
                        ).map(([district, count]) => (
                          <tr key={district}>
                            <td className="border px-4 py-2">{district}</td>
                            <td className="border px-4 py-2">{count}</td>
                          </tr>
                        ))}
                      {instituteAndDistrictCount.districtStudentCountMap && (
                        <tr>
                          <td className="border px-4 py-2 font-bold">Total</td>
                          <td className="border px-4 py-2 font-bold">
                            {Object.values(
                              instituteAndDistrictCount.districtStudentCountMap
                            ).reduce((total, count) => total + count, 0)}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div>
                  <h3 className="text-lg font-bold mb-2">
                    Institute Student Count
                  </h3>
                  <table className="table-auto">
                    <thead>
                      <tr>
                        <th className="border px-4 py-2">Institute</th>
                        <th className="border px-4 py-2">Count</th>
                      </tr>
                    </thead>
                    <tbody>
                      {instituteAndDistrictCount.instituteStudentCountMap &&
                        Object.entries(
                          instituteAndDistrictCount.instituteStudentCountMap
                        ).map(([institute, count]) => (
                          <tr key={institute}>
                            <td className="border px-4 py-2">{institute}</td>
                            <td className="border px-4 py-2">{count}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}
