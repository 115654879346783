import { gql } from "@apollo/client";

export const CREATE_COURSE_CATEGORY_MUTATION = gql`
  mutation insert_course_category(
    $name: String
    $description: String
    $visible: Boolean
    $slug: String
    $image_url: String
  ) {
    insert_courses_course_categories_one(
      object: {
        name: $name
        description: $description
        slug: $slug
        visible: $visible
        image_url: $image_url
      }
      on_conflict: {
        constraint: course_categories_slug_key
        update_columns: [description]
        where: { slug: { _eq: $slug } }
      }
    ) {
      id
      name
      image_url
    }
  }
`;

export const UPDATE_COURSE_CATEGORY_MUTATION = gql`
  mutation updateCourseCatgory(
    $id: bigint
    $name: String
    $description: String
    $slug: String
    $visible: Boolean
    $image_url: String
  ) {
    update_courses_course_categories(
      where: { id: { _eq: $id } }
      _set: {
        name: $name
        description: $description
        slug: $slug
        visible: $visible
        image_url: $image_url
      }
    ) {
      returning {
        id
        name
        image_url
      }
    }
  }
`;

export const CREATE_MOODLE_CONFIG_MUTATION = gql`
  mutation insert_moodle_config(
    $moodle_sp_name: String
    $moodle_sp_redirect_url: String
    $moodle_sp_client_id: String
    $moodle_sp_secret_key: String
    $moodle_sp_acs_url: String
  ) {
    insert_courses_moodle_config_one(
      object: {
        moodle_sp_name: $moodle_sp_name
        moodle_sp_redirect_url: $moodle_sp_redirect_url
        moodle_sp_secret_key: $moodle_sp_secret_key
        moodle_sp_client_id: $moodle_sp_client_id
        moodle_server_acs_url: $moodle_sp_acs_url
      }
      on_conflict: {
        constraint: moodle_config_moodle_sp_name_key
        update_columns: [moodle_server_acs_url]
        where: { moodle_sp_name: { _eq: $moodle_sp_name } }
      }
    ) {
      id
      moodle_sp_name
    }
  }
`;
export const UPDATE_MOODLE_CONFIG_MUTATION = gql`
  mutation updateMoodleConfigMutation(
    $id: bigint
    $moodle_server_acs_url: String
    $moodle_sp_client_id: String
    $moodle_sp_name: String
    $moodle_sp_redirect_url: String
    $moodle_sp_secret_key: String
  ) {
    update_courses_moodle_config(
      where: { id: { _eq: $id } }
      _set: {
        moodle_server_acs_url: $moodle_server_acs_url
        moodle_sp_client_id: $moodle_sp_client_id
        moodle_sp_name: $moodle_sp_name
        moodle_sp_redirect_url: $moodle_sp_redirect_url
        moodle_sp_secret_key: $moodle_sp_secret_key
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export const CREATE_COURSE_MUTATION = gql`
  mutation insesrt_course(
    $course_category_id: bigint
    $description: String
    $end_date: timestamptz
    $full_name: String
    $is_moodle_course: Boolean
    $moodle_config_id: bigint
    $moodle_course_url: String
    $publish: Boolean
    $start_date: timestamptz
    $identifier: String
    $cost: numeric
    $discount: numeric
    $duration: String
    $image_url: String
    $is_live_course: Boolean
    $moodle_course_id: String
    $nsqf_level: String
    $short_name: String
    $has_certificate: Boolean
    $video_url: String
    $is_taxable: Boolean
    $is_subscription: Boolean
    $course_type: Int
    $certificate_image_url: String
    $discontinued_from_moodle_at: timestamptz
  ) {
    insert_courses_course_one(
      object: {
        course_category_id: $course_category_id
        description: $description
        end_date: $end_date
        full_name: $full_name
        is_moodle_course: $is_moodle_course
        moodle_config_id: $moodle_config_id
        moodle_course_url: $moodle_course_url
        publish: $publish
        start_date: $start_date
        identifier: $identifier
        cost: $cost
        has_certificate: $has_certificate
        discount: $discount
        duration: $duration
        image_url: $image_url
        is_live_course: $is_live_course
        moodle_course_id: $moodle_course_id
        nsqf_level: $nsqf_level
        short_name: $short_name
        video_url: $video_url
        is_taxable: $is_taxable
        is_subscription: $is_subscription
        course_type: $course_type
        certificate_image_url: $certificate_image_url
        discontinued_from_moodle_at: $discontinued_from_moodle_at
      }
      on_conflict: {
        constraint: course_identifier_key
        where: { identifier: { _eq: "" } }
        update_columns: []
      }
    ) {
      id
      full_name
    }
  }
`;

export const UPDATE_COURSE_MUTATION = gql`
  mutation update_course(
    $course_category_id: bigint
    $description: String
    $end_date: timestamptz
    $full_name: String
    $is_moodle_course: Boolean
    $moodle_config_id: bigint
    $moodle_course_url: String
    $publish: Boolean
    $start_date: timestamptz
    $identifier: String
    $cost: numeric
    $discount: numeric
    $duration: String
    $image_url: String
    $is_live_course: Boolean
    $moodle_course_id: String
    $nsqf_level: String
    $short_name: String
    $id: bigint
    $video_url: String
    $course_instructions: String
    $certificate_image_url: String
    $is_taxable: Boolean
    $is_subscription: Boolean
    $has_certificate: Boolean
    $course_type: Int
    $discontinued_from_moodle_at: timestamptz
  ) {
    update_courses_course(
      where: { id: { _eq: $id } }
      _set: {
        cost: $cost
        course_category_id: $course_category_id
        description: $description
        discount: $discount
        duration: $duration
        end_date: $end_date
        full_name: $full_name
        identifier: $identifier
        image_url: $image_url
        is_live_course: $is_live_course
        is_moodle_course: $is_moodle_course
        moodle_config_id: $moodle_config_id
        moodle_course_id: $moodle_course_id
        moodle_course_url: $moodle_course_url
        nsqf_level: $nsqf_level
        publish: $publish
        short_name: $short_name
        start_date: $start_date
        video_url: $video_url
        has_certificate: $has_certificate
        course_instructions: $course_instructions
        certificate_image_url: $certificate_image_url
        is_taxable: $is_taxable
        is_subscription: $is_subscription
        course_type: $course_type
        discontinued_from_moodle_at: $discontinued_from_moodle_at
      }
    ) {
      returning {
        id
        full_name
      }
    }
  }
`;

export const CREATE_COURSE_BATCHES_MUTATION = gql`
  mutation insert_course_batches(
    $to_date: timestamptz
    $from_date: timestamptz
    $slot_start_time: String
    $slot_end_time: String
    $batch_name: String
    $course_id: Int
    $instructor_id: Int
    $slots_days: String
    $platform: String
    $min_learners: Int
    $max_learners: Int
    $partner_id: Int
    $meeting_link: String
    $repeat_end_time: timestamptz
    $project_id: bigint
    $type: String
    $location: String
    $is_demo: Boolean
    $institute_id: bigint
    $language_id: Int
    $registration_end_date: timestamptz
  ) {
    insert_courses_course_batches_one(
      object: {
        to_date: $to_date
        from_date: $from_date
        slot_start_time: $slot_start_time
        slot_end_time: $slot_end_time
        batch_name: $batch_name
        course_id: $course_id
        instructor_id: $instructor_id
        slots_days: $slots_days
        platform: $platform
        min_learners: $min_learners
        max_learners: $max_learners
        partner_id: $partner_id
        meeting_link: $meeting_link
        repeat_end_time: $repeat_end_time
        project_id: $project_id
        is_demo: $is_demo
        type: $type
        location: $location
        institute_id: $institute_id
        language_id: $language_id
        registration_end_date: $registration_end_date
      }
    ) {
      id
      batch_name
      instructor_id
      platform
      slots_days
      type
      location
    }
  }
`;

const CERTIFICATION_PARTNER_FIELDS = `
  id
  course_id
  partner_id
  course {
    id
    full_name
  }
  partner {
    id
    name
    logo
  }
`;

export const INSERT_CERTIFICATION_PARTNER_MUTATION = gql`
  mutation ($relations: [courses_course_partner_certification_insert_input!]!) {
    insert_courses_course_partner_certification (objects: $relations) {
      returning {
        ${CERTIFICATION_PARTNER_FIELDS}
      }
    }
  }
`;

export const DELETE_CERTIFICATION_PARTNER_MUTATION = gql`
  mutation ($partner_ids: [bigint!]!, $courseId: bigint) {
    delete_courses_course_partner_certification(
      where: {
        _and: [
          { partner_id: { _in: $partner_ids } }
          { course_id: { _eq: $courseId } }
        ]
      }
    ) {
      affected_rows
    }
  }
`;

export const USER_COURSE_ENROLL_AND_UNENROLL = gql`
  mutation MyMutation($enroll_status: Boolean, $id: Int) {
    update_courses_user_course_enrolment(
      where: { id: { _eq: $id } }
      _set: { enroll_status: $enroll_status }
    ) {
      affected_rows
    }
  }
`;

export const COURSE_ENABLE_DISABLE = gql`
  mutation MyMutation($id: bigint, $publish: Boolean) {
    update_courses_course(
      where: { id: { _eq: $id } }
      _set: { publish: $publish }
    ) {
      affected_rows
    }
  }
`;

export const CREATE_COURSE_CERTIFICATION_PARTNERS = gql`
  mutation createCourseCertificationPartners(
    $certificationPartners: [courses_course_partner_certification_insert_input!]!
  ) {
    insert_courses_course_partner_certification(
      objects: $certificationPartners
    ) {
      returning {
        id
        course_id
        partner_id
      }
      affected_rows
    }
  }
`;

export const DELETE_COURSE_CERTIFICATION_PARTNERS = gql`
  mutation deleteCertificationPartners($idArray: [Int!]!) {
    delete_courses_course_partner_certification(
      where: { id: { _in: $idArray } }
    ) {
      returning {
        id
      }
      affected_rows
    }
  }
`;

export const UPDATE_MODULE_TAGS = gql`
  mutation updateModuleTags($moduleId: Int, $tags: jsonb) {
    update_courses_course_module_mapping(
      where: { id: { _eq: $moduleId } }
      _set: { tags: $tags }
    ) {
      affected_rows
      returning {
        tags
        id
      }
    }
  }
`;

export const UPSERT_TRANSLATIONS = gql`
  mutation insertTranslations(
    $translationsArray: [courses_multi_lang_contents_insert_input!]!
  ) {
    insert_courses_multi_lang_contents(
      objects: $translationsArray
      on_conflict: {
        constraint: multi_lang_contents_key_key
        update_columns: [content]
      }
    ) {
      returning {
        key
        content
      }
      affected_rows
    }
  }
`;
