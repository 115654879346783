import React, { useState, useEffect, useMemo } from "react";
import { useLocation } from "react-router";
import queryString from "query-string";
import DataTable from "react-data-table-component";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import {
  GET_COURSE_ENROLLED_USERS,
  GET_COURSE_UNENROLLED_USERS,
  GET_USERS_COURSE_PROGRESS_FILTERED,
} from "GraphQl/Queries/CourseBuilder/Course";
import moment from "moment";
import { USER_COURSE_ENROLL_AND_UNENROLL } from "GraphQl/Mutations/Courses";
import { successHandler, errorHandler } from "utils/toast";
import Modal from "react-bootstrap/Modal";
import UnEnrolledUserTable from "./UnEnrolledUserTable";
import {
  CREATE_COURSE_ENROLLMENT,
  DELETE_USER_COURSE_ENROLLMENT,
} from "GraphQl/Mutations/coursebuildemutation/CourseModule";
import Input from "components/InputGroup/Input";
import ExportReportModal from "./ExportReportModal";
import { GET_COURSE_TOPICS } from "GraphQl/Queries/CourseBuilder/section";

import CourseUserProgressReport from "services/coursebuild/CourseUserProgressReport";
import { useRecoilState } from "recoil";
import { currentModuleAccessRecoilVar } from "State/Navigation";
import { gqlUserRecoilVar } from "State/User";
import Container from "components/Container/Container";
import { GET_COURSES_WITH_FILTER } from "GraphQl/Queries/Courses";
import UsersFilter from "components/TraineeList/UsersFilter";
import Axios from "../../../../src/api/Api";
import courseSvc from "services/course";
import { asdmCourseReportConfig } from "api/Consts";
import ASDMSkillYatraReport from "./CourseEnrolledUsers/ASDMSkillYatraReport";
import JBFDailyReports from "./JBFDailyReports";

export default function CourseEnrolledUsers() {
  const location = useLocation();
  const params = queryString.parse(location.search);

  const [listingCriteria, setListingCriteria] = useState({});

  const selectedCourseId = params.id;

  const [user] = useRecoilState(gqlUserRecoilVar);

  const [currentModuleAccess] = useRecoilState(currentModuleAccessRecoilVar);
  const allowedPartnerIds = useMemo(
    () => Object.keys(currentModuleAccess?.allowedPartners || {}),
    [currentModuleAccess]
  );

  const {
    partnerId,
    projectId,
    couponId,
    courseId,
    name,
    email,
    mobileNumber,
  } = listingCriteria;

  const userSearchExp = useMemo(() => {
    const filterCourseId = courseId || selectedCourseId;

    const obj = {
      name: { _ilike: `%${name || ""}%` },
      email: { _ilike: `%${email || ""}%` },
      mobile_number: { _ilike: `%${mobileNumber || ""}%` },
    };
    if (filterCourseId) {
      Object.assign(obj, {
        enrollments: {
          course_id: {
            _eq: filterCourseId,
          },
        },
      });
    }
    if (couponId)
      Object.assign(obj, {
        st_user_coupons: {
          st_coupons_configuration: {
            id: { _eq: couponId },
          },
        },
      });

    if (partnerId)
      Object.assign(obj, {
        partner_users: {
          partner_id: { _eq: partnerId },
        },
      });
    else {
      // @to-revert
      if (!user || (!user.is_skillstrainer_admin && !currentModuleAccess))
        Object.assign(obj, {
          partner_users: {
            partner_id: { _eq: -1 },
          },
        });
      else if (!user.is_skillstrainer_admin)
        Object.assign(obj, {
          partner_users: {
            partner_id: {
              _in: Object.keys(currentModuleAccess.allowedPartners),
            },
          },
        });
    }

    if (projectId)
      Object.assign(obj, {
        partner_projects: {
          project_id: { _eq: projectId },
        },
      });

    return obj;
  }, [
    name,
    email,
    mobileNumber,
    couponId,
    courseId,
    partnerId,
    projectId,
    user,
    currentModuleAccess,
    selectedCourseId,
  ]);

  const courseSearchExp = useMemo(() => {
    if (!user) return { course_id: { _eq: -1 } };
    else if (!user.is_skillstrainer_admin)
      return {
        _or: [
          {
            partner_revenue_split_directives: {
              partner_id: { _in: allowedPartnerIds },
            },
          },
          {
            criteria_courses: {
              criteria: {
                coupon: {
                  partner_id: { _in: allowedPartnerIds },
                },
              },
            },
          },
        ],
      };
    else return {};
  }, [user, allowedPartnerIds]);

  const { data: allowedCoursesRes } = useQuery(GET_COURSES_WITH_FILTER, {
    variables: { courseSearchExp },
    onError: console.error,
  });
  const allowedCourses = allowedCoursesRes?.courses_course;

  const [enrolledUsers, setEnrolledUsers] = useState([]);
  const [lgShow, setLgShow] = useState(false);
  const [lgShowReport, setLgShowReport] = useState(false);
  const [searchText, setsearchText] = useState("");
  const [reports, setReports] = useState([]);
  const [checkedTopics, setCheckedTopics] = useState([]);
  const [ischecked, setIsChecked] = useState([]);

  //GET ENROLLED USERS PROGRESS
  const { data: usersProgress, loading } = useQuery(
    GET_USERS_COURSE_PROGRESS_FILTERED,
    {
      variables: {
        course_id: courseId
          ? courseId
          : selectedCourseId
          ? selectedCourseId
          : {},
        id: courseId ? courseId : selectedCourseId ? selectedCourseId : {},
        userSearchExp,
      },
      fetchPolicy: "network-only",
    }
  );

  // Get Course Topics
  const { data: courseTopics } = useQuery(GET_COURSE_TOPICS, {
    variables: {
      course_id: courseId ? courseId : selectedCourseId ? selectedCourseId : {},
    },
    fetchPolicy: "network-only",
  });

  //GET UnENROLLED USERS
  const [runQuery, unEnrolledLists] = useLazyQuery(
    GET_COURSE_UNENROLLED_USERS,
    {
      variables: {
        userSearchExp,
        course_id: courseId
          ? courseId
          : selectedCourseId
          ? selectedCourseId
          : {},
      },
      fetchPolicy: "network-only", // Doesn't check cache before making a network request
    }
  );

  //Create Enrollment
  const [createEnrollemt] = useMutation(CREATE_COURSE_ENROLLMENT, {
    refetchQueries: () => [
      {
        query: GET_COURSE_ENROLLED_USERS,
        variables: {
          course_id: courseId
            ? courseId
            : selectedCourseId
            ? selectedCourseId
            : {},
        },
      },
      {
        query: GET_COURSE_UNENROLLED_USERS,
        variables: {
          userSearchExp,
          course_id: courseId
            ? courseId
            : selectedCourseId
            ? selectedCourseId
            : {},
        },
      },
    ],
    onCompleted: (data) => {
      successHandler("User Enrolled !");
    },
    onError: () => errorHandler("An error occurred"),
  });

  //Delete Enrollment
  const [deleteUserEnrollemt] = useMutation(DELETE_USER_COURSE_ENROLLMENT, {
    refetchQueries: () => [
      {
        query: GET_COURSE_ENROLLED_USERS,
        variables: {
          course_id: courseId
            ? courseId
            : selectedCourseId
            ? selectedCourseId
            : {},
        },
      },
      {
        query: GET_COURSE_UNENROLLED_USERS,
        variables: {
          userSearchExp,
          course_id: courseId
            ? courseId
            : selectedCourseId
            ? selectedCourseId
            : {},
        },
      },
    ],
    onCompleted: (data) => {
      successHandler("User Enrolled !");
    },
    onError: () => errorHandler("An error occurred"),
  });

  //Update Enrollment
  const [userEnrollmentOperation] = useMutation(
    USER_COURSE_ENROLL_AND_UNENROLL,
    {
      refetchQueries: () => [
        {
          query: GET_COURSE_ENROLLED_USERS,
          variables: {
            course_id: courseId
              ? courseId
              : selectedCourseId
              ? selectedCourseId
              : {},
          },
        },
      ],
      onCompleted: (data) => {
        successHandler("User Enrollment Updated successfully!");
      },
      onError: () => errorHandler("An error occurred"),
    }
  );

  useEffect(() => {
    //get course users
    const courses_user_course_enrolment =
      usersProgress?.courses_user_course_enrolment;

    //get course moduels
    const topics = courseTopics?.courses_course_module_mapping;

    // filter by selected topics
    const topicResult =
      checkedTopics && checkedTopics.length > 0
        ? topics.filter((u) => checkedTopics.includes(u.id))
        : topics;

    setEnrolledUsers(courses_user_course_enrolment);

    // return false if no topics or no enrollment in course
    if (
      !topicResult ||
      topicResult.length === 0 ||
      !courses_user_course_enrolment ||
      courses_user_course_enrolment.length === 0
    ) {
      return false;
    }

    const getReportFun = async () => {
      return await CourseUserProgressReport(usersProgress, topicResult).then(
        (response) => setReports(response)
      );
    };

    getReportFun();

    const ids = topicResult?.map((e) => e.id);
    setIsChecked(ids);
  }, [usersProgress, courseTopics, checkedTopics]);

  const userEnrollmentFun = (id, status) => {
    userEnrollmentOperation({
      variables: {
        id: id,
        enroll_status: !status,
      },
    });
  };

  //Delete Enrollment
  const deleteEnrollmentFun = (id) => {
    if (window.confirm("Are you really want to delete user enrollment?")) {
      deleteUserEnrollemt({
        variables: {
          id: id,
        },
      });
    }
  };

  //Topic check uncheck
  const handleCheckedTopics = (values) => {
    setCheckedTopics(values);
  };

  const topicSelectAll = (select) => {
    if (!select) {
      setIsChecked([]);
    } else {
      const ids = courseTopics?.courses_course_module_mapping.map((e) => e.id);
      setIsChecked(ids);
      setCheckedTopics(ids);
    }
  };

  const [paginationInfo, setPaginationInfo] = useState({
    page: 1,
    per_page: 10,
  });

  const columns = [
    {
      name: "Enrollment Id",
      selector: (row, index) => `${row.id}`,
      width: "10rem",
      //   sortable: true,
    },
    {
      name: "User Id",
      selector: (row, index) => `${row.user.id}`,
      sortable: true,
      width: "12rem",
    },
    {
      name: "User Name",
      selector: (row, index) => `${row.user.name}`,
      sortable: true,
      width: "",
    },
    {
      name: "User Email",
      selector: (row, index) => `${row.user.email}`,
      sortable: true,
      width: "",
    },
    {
      name: "Enrolled Date",
      selector: (row, index) => moment(row.created_at).format("YYYY/MM/DD"),
      sortable: true,
    },
    {
      name: "Enrolled Status",
      selector: (row, index) => (row.enroll_status ? "Enrolled" : "UnEnrolled"),
      sortable: true,
    },
    {
      cell: (row) => (
        <button
          className="bg-gray-700 p-2 text-base text-white"
          onClick={() => deleteEnrollmentFun(row.id)}
        >
          Delete Enrollment
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: "12rem",
    },
    {
      cell: (row) => (
        <button
          className="bg-gray-700 p-2 text-base text-white"
          onClick={() => userEnrollmentFun(row.id, row.enroll_status)}
        >
          {row.enroll_status ? "UnEnroll User" : "Enroll User"}
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: "12rem",
    },
  ];
  const conditionalRowStyles = [
    {
      when: (row) => !row.enroll_status,
      style: {
        backgroundColor: "gray",
        userSelect: "none",
      },
    },
  ];

  const handlePageChange = (page) =>
    setPaginationInfo({ ...paginationInfo, page: page });

  const handlePerRowsChange = async (newPerPage, page) =>
    setPaginationInfo({ ...paginationInfo, page: page, per_page: newPerPage });

  //Manual create enrollment
  const userManualEnrollmentFun = async (id) => {
    try {
      // If course is a moodle course, then checking if user's moodle user is created
      const selectedCourse = await courseSvc.getCourseById(selectedCourseId);
      if (selectedCourse.is_moodle_course) {
        const response = await Axios().post("/get_moodle_user_enrolled", {
          id,
        });

        if (response.data.data === null) {
          return errorHandler(
            "User is not created in Moodle. Please try after some time !"
          );
        }
      }

      createEnrollemt({
        variables: {
          user_id: id,
          course_id: selectedCourseId,
          enroll_status: true,
        },
      });
    } catch (err) {
      console.log("err===", err) ||
        errorHandler("An Error Occured while Enroll User");
    }
  };

  useEffect(() => {
    if (searchText) {
      const variables = {};

      const graphql_exp = {};
      graphql_exp.email = { _ilike: `%${searchText}%` };
      variables.where = graphql_exp;

      runQuery({
        variables: variables,
      });
    }
  }, [searchText]);

  return (
    <Container title="Course User Reports">
      <UsersFilter
        updateListingCriteria={setListingCriteria}
        selectedCourseId={selectedCourseId}
      />

      {courseId === 221 && user?.is_skillstrainer_admin && <JBFDailyReports />}
      {/* Manual Enrol Moda; */}
      <Modal
        size="lg"
        show={lgShow}
        fullscreen
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Manual Enroll Users
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="w-3/12 mb-5">
            <label className="block text-sm font-medium text-gray-700 mx-4 my-2">
              Search By Email
            </label>
            <Input
              type="text"
              name="search"
              value={searchText}
              onChange={(e) => setsearchText(e.target.value)}
              placeholder={"Search By Email"}
            />
          </div>
          {searchText ? (
            <UnEnrolledUserTable
              users={unEnrolledLists.data?.courses_users}
              userManualEnrollmentFun={userManualEnrollmentFun}
              enrolledUsers={enrolledUsers}
            />
          ) : (
            "Please Type User Email On Search Field"
          )}
          {/* <BatchTableModal batch_slots={data?.courses_batch_slots || []} /> */}
        </Modal.Body>
      </Modal>

      {/* Report Modal */}
      <Modal
        size="lg"
        show={lgShowReport}
        fullscreen
        onHide={() => setLgShowReport(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Export Reports
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ExportReportModal
            enrolledUsers={enrolledUsers}
            courseTopics={courseTopics}
            lgShowReport={lgShowReport}
            reports={reports}
            handleChecked={handleCheckedTopics}
            ischecked={ischecked}
            topicSelectAll={topicSelectAll}
          />
        </Modal.Body>
      </Modal>
      <div className="flex justify-end mt-10 mb-3 gap-2">
        <button className="btn-primary" onClick={() => setLgShow(true)}>
          Enroll Manually
        </button>

        <button onClick={() => setLgShowReport(true)} className="btn-primary">
          Export Report
        </button>
      </div>

      {asdmCourseReportConfig.courseId === courseId &&
      asdmCourseReportConfig.projectId === projectId ? (
        <ASDMSkillYatraReport />
      ) : (
        <DataTable
          columns={columns}
          data={usersProgress?.courses_user_course_enrolment || []}
          progressPending={loading}
          pagination
          paginationTotalRows={enrolledUsers?.length}
          selectableRows={false}
          selectableRowsHighlight={false}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handlePerRowsChange}
          conditionalRowStyles={conditionalRowStyles}
        />
      )}
    </Container>
  );
}
