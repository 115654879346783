import { gql } from "@apollo/client";

export const GET_ALL_LANGUAGES = gql`
  query getAllLanguages {
    courses_languages {
      id
      display_name
    }
  }
`;
